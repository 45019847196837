import React, { useEffect, useState } from 'react'
import { Button, Form, Container, Header } from 'semantic-ui-react'
import ReactLoading from 'react-loading';
import './App.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

export default function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    phone: '',
    car: '',
    job: '',
    caller: '',
    type: '',
    password: ''
  })
  const [type, setType] = useState('')
  const [gender, setGender] = useState('')

  useEffect(() => {
    setFormData({
      ...formData,
      type,
      gender
    })
    // eslint-disable-next-line
  }, [type, gender])

  const changeHandler = (e, type) => {
    setFormData({ ...formData, [typeof type !== 'undefined' ? type : [e.target.name]]: typeof type !== 'undefined' ? p2e(e.target.innerText) : p2e(e.target.value) })
  }

  const changeType = (e, { value }) => {
    setType(value);
  }
  
  const changeGender = (e, { value }) => {
    setGender(value);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    const copyData = {...formData}
    for (const iterator of Object.getOwnPropertyNames(copyData)) {
      data.append(iterator, copyData[iterator])
    }

    const sheetUrl = "https://script.google.com/macros/s/AKfycbzFeXdnKHqv5yzX4-Da4gTpxWjFng-CGgDXDnuFB4zpZx-d04u_EjYtV-2v2IBMzTLt/exec"
    try {
      console.log(formData)
      setIsLoading(true);
      const res = await fetch(sheetUrl, {
        method: 'POST',
        body: JSON.stringify(copyData),
        muteHttpExceptions: true,
        headers: {
          'Content-Type': "text/plain;charse=utf=8"
        }
      });

      const result = await res.json()

      if (result && result.result === 'success')
      {
        setFormData({
          name: '',
          gender: '',
          phone: '',
          car: '',
          job: '',
          caller: '',
          type: '',
          password: ''
        });
        setType('');
        setGender('');
        NotificationManager.success('اطلاعات ثبت شد', 'تایید', 3000)
        setIsLoading(false)
        }
        
      else
      {
        NotificationManager.error('عملیات با خطا مواجه شد', 'خطا', 3000)
        setIsLoading(false);
      }
        
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }
 
  return (
    <div className='bg'>
      <Container fluid className="container">
        {isLoading ? <ReactLoading className='loading-center' type={'bubbles'} color={'#000000'} width={'500px'} height={'40vh'}/> : <>
        <Header as='h2' className='font'>فرم ثبت اطلاعات</Header>
          <Form className="form">
          <Form.Field>
            <label>نام مشتری</label>
            <input name='name' value={formData.name} onChange={changeHandler} placeholder='نام و نام خانوادگی را بنویسید' />
          </Form.Field>
          <Form.Field>
              <label>جنسیت</label>
            <Form.Radio
                label='آقا'
                value={'مرد'}
                checked={gender === 'مرد'}
                onChange={changeGender}
              />
              <Form.Radio
                label='خانم'
                value={'زن'}
                checked={gender === 'زن'}
                onChange={changeGender}
              />
            </Form.Field>
          <Form.Field>
            <label>شماره تماس</label>
          <input name='phone' value={formData.phone} onChange={changeHandler} placeholder='نام و نام خانوادگی را بنویسید' />
          </Form.Field>
          <Form.Field>
            <label>ماشین</label>
            <input name="car" value={formData.car} onChange={changeHandler} placeholder='ماشین مربوطه را بنویسید' />
          </Form.Field>
          <Form.Field>
            <label>شغل مشتری</label>
            <input name="job" value={formData.job} onChange={changeHandler} placeholder='شغل مشتری را بنویسید یا خالی بگزارید' />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown value={formData.caller} onChange={(e) => changeHandler(e, 'caller')} placeholder="نام کارگذار" fluid selection options={[
                { key: 1, text: "سامان الوندی", value:"سامان الوندی" },
                { key: 2, text: "زهرا نوروزی", value:"زهرا نوروزی" },
                { key: 3, text: "سجاد یوسفی", value: "سجاد یوسفی" },
                { key: 4, text: "ایلیا امانیان", value: "ایلیا امانیان" },
                { key: 5, text: "رضا جاویدی", value: "رضا جاویدی" },
                { key: 6, text: "الهام برومند", value: "الهام برومند" },
              ]}/>
          </Form.Field>
            <Form.Field>
              <label>نوع مشتری</label>
              <Form.Radio
                label='دلال'
                value={'دلال'}
                checked={type === 'دلال'}
                onChange={changeType}
              />
              <Form.Radio
                label='فروشنده'
                value={'فروشنده'}
                checked={type === 'فروشنده'}
                onChange={changeType}
              />
              <Form.Radio
                label='خریدار'
                value={'خریدار'}
                checked={type === 'خریدار'}
                onChange={changeType}
              />
              <Form.Radio
                label='ناشناس'
                value={'ناشناس'}
                checked={type === 'ناشناس'}
                onChange={changeType}
              />
          </Form.Field>
          <Form.Field>
              <label>رمز عبور</label>
            <input type='password' name="password" value={formData.password} onChange={changeHandler} placeholder='رمز عبور را وارد کنید' />
          </Form.Field>
          <Button className='font' onClick={onSubmit} color="blue" type='submit'>ثبت اطلاعات</Button>
        </Form></>}
      </Container>

      <NotificationContainer/>
    </div>
    )
}